import React, { useEffect } from "react";
import storeImage from "../assets/store.jpg";

export default function StoreA() {

    const imageMapAreas = [
        {
            id: "dustbin",
            alt: "dustbin alt",
            title: "Dustbin",
            shape: "poly",
            points: [543,405,582,407,573,464,548,464]
        },
        {
            id: "fridgeHandle2",
            alt: "fridge handle 2 alt",
            title: "fridge handle 2",
            shape: "rect",
            points: [441,325,466,378]
        },
        {
            id: "protein",
            alt: "protein alt",
            title: "Protein",
            shape: "circle",
            points: [686,540,65]
        }
    ]

    const imageRef = React.useRef<any>(null);
    const naturalImageWidth = React.useRef<number|null>(null);
    const naturalImageHeight = React.useRef<number|null>(null);
    const imageWidth = React.useRef<number|null>(null);
    const imageHeight = React.useRef<number|null>(null);
    const [scale,setScale] = React.useState<number>(1.0);

    function loadImageDimensions() {
        naturalImageWidth.current = imageRef?.current?.naturalWidth;
        naturalImageHeight.current = imageRef?.current?.naturalHeight;
        imageWidth.current = imageRef?.current?.width;
        imageHeight.current = imageRef?.current?.height;
        if (imageWidth?.current && naturalImageWidth?.current) {
            setScale(imageWidth.current/naturalImageWidth.current);
        } 
        console.log(
            `loaded image dimensions naturalSize=[${naturalImageWidth.current}, ${naturalImageHeight.current}],`,
            `actualSize=[${imageWidth.current}, ${imageHeight.current}],`,
            `scale=${scale}`
        );
    }

    function handleWindowResize() {
        loadImageDimensions();
    }

    function handleImageLoad(event:any) {
        loadImageDimensions();
    }

    function handleStoreClick(event:any) {
        console.log("click event!!!", event, ", id=", event.target.id);
        alert(event.target.id);
    }

    useEffect(
        () => {
            console.log("adding resize event listener!!!");
            window.addEventListener("resize", handleWindowResize);
            return () => {
                console.log("removing resize event listener!!!")
                window.removeEventListener("resize", handleWindowResize);
            }
            
        }
    )

    return(
        <>
            This is store A!
            <br/>
            <img ref={imageRef} src={storeImage} useMap="#lmckmap" alt="My Store"
                style={
                    {
                        width: "100%",
                        height: "auto"
                    }
                }
                onLoad={handleImageLoad}
                onChange={() => console.log("aaaxxx!")}
            />
            <map name="lmckmap">
                {
                    imageMapAreas.map(
                        a => <area id={a.id} key={a.id} alt={a.alt} title={a.title} href="#" shape={a.shape}
                                onClick={handleStoreClick}
                                coords={a.points.map(p => p * scale).join(",")}    
                            />
                    )
                }
            </map>
        </>
    )

}