import React from 'react';
import './App.css';
import { 
  Switch,
  Route,
  Link
} from "react-router-dom"
import StoreA from "./routes/StoreA";
import Home from "./routes/Home";

function App() {
  return (
    <div className="App">
      Hey! <Link to="/home">Home</Link> <Link to="/storeA">Store A</Link>
      <br/>
      <Switch>
        <Route path="/storeA">
          <StoreA />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>

    </div>
  );
}

export default App;
